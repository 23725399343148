import { PageHeader } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { PageLoaderLayer } from '../../../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { Stepper } from '../../../../../components/general/stepper/stepper';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { useAppActions, useAppState } from '../../../../../overmind';
import { SortField } from '../../../../../enums';
import SummaryStepLayout from '../summary-step/summary-step.layout';
import { DetailsStepLayout } from '../details-step/details-step-layout';
import { PeopleStepLayout } from '../people-step/people-step.layout';
import { SkillsStepLayout } from '../skills-step/skills-step.layout';
import { CompanyEntitySearchParams, CompanyRoleAssigneesSearchParams } from '../../../../../models/overmind/search-params';
import { EntityType, OrganizationLevelType } from '../../../../../enums';
import { CompanyRoleListItemResponse, CompanySkillListItemResponse } from '../../../../../models/overmind/company-entity';
import { AIGenerateRoleRequest } from '../../../../../overmind/actions';
import { CompanyRoleAssignee } from '../people-step/people-step.models';
import { Proficiency } from '../../../../../models/proficiency';
import { useStepperConnector } from '../../../../../components/general/stepper/use-stepper-connector';
import { StepIds } from '../wizard.models';
import { Step } from '../../../../../components/general/stepper/step/step';

export function ImportRoleStepperPage(): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [seniorities, setSeniorities] = useState<Proficiency[]>([]);
  const [roleSlug, setRoleSlug] = useState<string>();
  const [role, setRole] = useState<AIGenerateRoleRequest>();
  const [selectedRole, setSelectedRole] = useState<CompanyRoleListItemResponse>();
  const [skills, setSkills] = useState<CompanySkillListItemResponse[]>([]);
  const [people, setPeople] = useState<CompanyRoleAssignee[]>([]);

  const stepperConnector = useStepperConnector();

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const seniorityLevelsResponse = await actions.getSeniorityLevels(companyVariables.slug!);
      setSeniorities(seniorityLevelsResponse ?? []);
      setIsLoading(false);
    }

    getData();
  }, []);

  useEffect(() => {
    async function getRole() {
      if (!roleSlug) return;
      const response = await actions.getCompanyRole({ companySlug: companyVariables.slug!, roleSlug });
      setSelectedRole(response);
    }

    async function getPeople() {
      if (!roleSlug) return;
      const request: CompanyRoleAssigneesSearchParams = {
        search: undefined,
        sortAscending: true,
        sortField: SortField.Name,
        pageSize: 99999, // TODO: high pageSize to fetch ALL people
        page: 1,
        organizationLevel: OrganizationLevelType.Company,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        roleSlug,
      };
      const response = await actions.getCompanyRoleAssignees(request);
      // TODO: fix model so we don't need to do all the below
      setPeople(
        response?.assignees.map(assignee => {
          return {
            selected: true,
            name: assignee.name,
            email: assignee.email ?? '',
            department: assignee.department ?? '',
            team: assignee.team ?? '',
            entitySlug: assignee.slug ?? '',
            organizationLevel: OrganizationLevelType.Company,
          };
        }) ?? []
      );

      setIsLoading(false);
    }

    async function getData() {
      setIsLoading(true);
      await getRole();
      await getPeople();
      setIsLoading(false);
    }

    getData();
  }, [roleSlug, stepperConnector.activeStepIndex]);

  return (
    <PageLoaderLayer path={PagePath.roleSkillManagementWizardImportRole}>
      <div className="wrapper stack" style={{ paddingBottom: 24, gap: 24 }}>
        <PageHeader
          breadcrumbs={[
            { name: 'Role & Skill Management', url: `${PagePath.roleSkillManagementBase}` },
            { name: 'Role Wizard', url: `${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementWizard.replace(':companySlug', companyVariables.slug!)}` },
            { name: `${!!roleSlug ? selectedRole?.name : 'Import Role'}`, url: window.location.href },
          ]}
          title={!!roleSlug ? selectedRole?.name : 'Import role'}
          subtitle={!!roleSlug ? 'Understand role description, view essential skills and performance standards.' : 'Define job roles, assign essential skills, and set performance standards.'}
          divider
        />

        <Stepper {...stepperConnector}>
          <Step key={StepIds.Details} label={StepIds.Details} disabled={!!roleSlug} completed={!!roleSlug} required>
            <DetailsStepLayout
              path={PagePath.roleSkillManagementWizardImportRole}
              seniorities={seniorities}
              roleSlug={roleSlug}
              selectedRole={selectedRole}
              parentIsLoading={isLoading}
              setSelectedRole={setSelectedRole}
              completeStep={data => {
                setRoleSlug(data.roleSlug);
                setRole(data.role);
                setSkills(data.skills ?? []);
                stepperConnector.next();
              }}
            />
          </Step>

          <Step key={StepIds.Skills} label={StepIds.Skills} completed={!!skills.length} required>
            <SkillsStepLayout
              path={PagePath.roleSkillManagementWizardImportRole}
              parentIsLoading={isLoading}
              roleSlug={roleSlug!}
              role={role!}
              skills={skills}
              completeStep={async () => {
                // TODO: should this be moved to 'useEffect' with 'getRole' and 'getPeople'? or should they be moved to their respective 'completeStep' functions?
                setIsLoading(true);

                const request: CompanyEntitySearchParams = {
                  search: undefined,
                  sortAscending: true,
                  pageSize: 99999, // ridiculously high pageSize to fetch ALL skills
                  page: 1,
                  organizationLevel: OrganizationLevelType.Company,
                  companySlug: companyVariables.slug,
                  departmentSlug: undefined,
                  teamSlug: undefined,
                  learnerSlug: undefined,
                  searchGlobally: false,
                  relatedType: EntityType.Role,
                  roleSlug,
                };
                const response = await actions.getCompanySkills(request);
                setSkills(response?.entities ?? []);

                setIsLoading(false);

                stepperConnector.next();
              }}
              goToPreviousStep={stepperConnector.previous}
            />
          </Step>

          <Step key={StepIds.People} label={StepIds.People} completed={!!people.length}>
            <PeopleStepLayout parentIsLoading={isLoading} roleSlug={roleSlug} assignedPeople={people} goToPreviousStep={stepperConnector.previous} completeStep={stepperConnector.next} />
          </Step>

          {/* TODO: <Step key={StepId.Summary} label={StepId.Summary} completed={role.active}> */}
          <Step key={StepIds.Summary} label={StepIds.Summary} disabled={!roleSlug || !skills.length}>
            <SummaryStepLayout path={PagePath.roleSkillManagementWizardImportRole} role={selectedRole} skills={skills} people={people} goToStep={stepperConnector.goTo} completeStep={stepperConnector.next} /> {/* TODO, redundant stepperConnector.next */}
          </Step>
        </Stepper>
      </div>
    </PageLoaderLayer>
  );
}
