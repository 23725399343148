import React from 'react';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import { IEmptyStateProps } from './empty-state.models';
import { Button, colourString } from '@keplerco/core';
import styles from './empty-state.module.css';

export function EmptyState(props: IEmptyStateProps): JSX.Element {
  const badgeIconWidth = props.badgeIconWidth === 'full-width' ? '100%' : `${props.badgeIconWidth ?? 500}px`;

  return (
    <div className={styles.container}>
      {!props.noBadgeIcon && (
        <div className={styles.iconContainer} style={{ maxWidth: badgeIconWidth }}>
          <object
            data={props.badgeUrl ?? themedAssetUrl('graphics/empty-state-cow.graphic.svg')}
            type="image/svg+xml"
            className={styles.icon}
            aria-label="Empty state icon"
          >
            <img
              src={props.badgeUrl ?? themedAssetUrl('graphics/empty-state-cow.graphic.svg')}
              alt=""
              className={styles.icon}
            />
          </object>

          <div style={{ textAlign: 'center' }}>
            {(!!props.title || (!props.title && !props.subtitle)) && (
              <h4 className="heading4" style={{ color: colourString(props.color ?? 'text') }}>
                {props.title ?? 'No results'}
              </h4>
            )}

            {!!props.subtitle && <p className="body2">{props.subtitle}</p>}
          </div>
        </div>
      )}

      {!!props.buttons && props.buttons.length !== 0 && (
        <div className={styles.buttons}>
          {props.buttons?.map(button => (
            <Button key={Date.now() + Math.random()} type="button" arrow={false} filled onClick={button.onClick}>
              {button.text}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
}