import React, { useEffect, useState } from 'react';
import { Assignee, TagAssigneeRequest, TagListItem } from '../../../../../models/tag';
import { TagType } from '../../../../../enums/tag';
import { useAppActions, useAppState } from '../../../../../overmind';
import { FetchType } from '../../../../../enums';
import { TagInput } from '../../../../../components/inputs/tags/tag-input';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { BaseSearchRequest } from '../../../../../models/overmind/search-request';
import { Anchor, Button } from '@keplerco/core';
import { EntitiesCardWidget } from '../../../../../components/cards/entities-card.widget';
import { useKeplerNavigate } from '../../../../../navigation/guards/use-kepler-navigate';
import classNames from 'classnames';
import layoutStyles from '../../../../../design/layout.module.css';
import { IActivateStepProps } from './activate-step.models';
import { StepSkeleton } from '../../../../../components/general/stepper/step/step.skeleton';

export function ActivateStepLayout({ assessmentSlug, isLoading, setIsLoading, onBack }: IActivateStepProps): JSX.Element {
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [tags, setTags] = useState<TagListItem[] | undefined>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    async function getData() {
      setIsLoading(true);

      const response = await actions.getAssigneeTags({ entitySlug: assessmentSlug, tagType: TagType.Assessment, companySlug: companyVariables.slug! });
      setTags(!!response ? response : []);

      setIsLoading(false);
    }

    getData();
  }, [assessmentSlug]);

  if (isLoading) return <StepSkeleton />;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 24, width: '100%' }}>
      <EntitiesCardWidget title="Tag" description="Select tag for the assessment.">
        <TagInput
          entityLabel="assessment"
          onRemoveTag={async tagName => {
            const assignees: TagAssigneeRequest[] = [
              {
                entitySlug: assessmentSlug,
                tagType: TagType.Assessment,
              },
            ];

            await actions.removeAssignees({ tagName, assignees, companySlug: companyVariables.slug! });
          }}
          getAssignees={() => [
            {
              entitySlug: assessmentSlug,
              tagType: TagType.Assessment,
            },
          ]}
          onCreateTag={async (tagName: string, assignees: Assignee[], source: string) => {
            if (source === 'input') {
              tagName = search;
              assignees = [
                {
                  entitySlug: assessmentSlug,
                  tagType: TagType.Assessment,
                },
              ];
            }

            await actions.addAssignees({ tagName, assignees, companySlug: companyVariables.slug! });
          }}
          initialTags={tags}
          onTagChange={setTags}
          searchTags={async (query: string) => {
            setSearch(query);
            const searchTagParams: BaseSearchRequest = {
              page: 1,
              pageSize: 15,
              search: query,
              sortAscending: true,
              sortField: 0,
              companySlug: companyVariables.slug!,
            };
            const paginatedResult = await actions.searchTags(searchTagParams);

            if (paginatedResult && paginatedResult.tags) {
              return paginatedResult.tags;
            }

            return [];
          }}
        />
      </EntitiesCardWidget>

      <footer className={classNames('card', layoutStyles.wizardFooter)}>
        <Anchor arrow reverse onClick={onBack}>
          Back
        </Anchor>

        <div style={{ display: 'flex', gap: 12 }}>
          <Anchor onClick={() => keplerNavigate(`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementAllAssessments.replace(':companySlug', companyVariables.slug!)}`)}>Save draft</Anchor>

          <Button
            type="button"
            filled
            onClick={async () => {
              actions.startLoader({ path: PagePath.assessmentManagementWizardFOP, type: FetchType.Sending });

              await actions.completeEndorsementSkillAssignment({ assessmentSlug: assessmentSlug });
              await actions.activateSkillAssessment({ companySlug: companyVariables.slug!, assessmentSlug: assessmentSlug });

              keplerNavigate(`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementAllAssessments.replace(':companySlug', companyVariables.slug!)}`);
              actions.stopLoader(PagePath.assessmentManagementWizardFOP);
            }}
          >
            Activate
          </Button>
        </div>
      </footer>
    </div>
  );
}
