import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { IFramePlayerModalPlaylistItem, IFramePlayerModalProps } from './iframe-player-modal.models';
import { LockIcon } from '../../../cards/lesson-card/lock.icon';
import { FullscreenModal } from '../fullscreen-modal/fullscreen-modal';
import { generateIFrameUrl } from '../iframe.helpers';
import { StartIcon } from '../../../../design/icons/start.icon';
import { CompletionStatus } from '../../../../enums';
import { Anchor, LoaderIcon, colourString, useMediaQuery } from '@keplerco/core';
import { DoubleChevronIcon } from '../../../../design/icons/double-chevron.icon';
import iFrameStyles from '../iframe.module.css';
import styles from './iframe-player-modal.module.css';

function generateButtonText(completionStatus: CompletionStatus): string {
  if (completionStatus === CompletionStatus.Completed) return 'Review Lesson'; // TODO: Viewed
  return completionStatus === CompletionStatus.InProgress ? 'Resume' : 'Start';
}

function generateCompletionStatusText(completionStatus: CompletionStatus): string {
  return completionStatus === CompletionStatus.Completed ? 'Completed' : completionStatus === CompletionStatus.InProgress ? 'In progress' : 'Upcoming';
}

export function IFramePlayerModal(props: IFramePlayerModalProps): JSX.Element {
  const { show, onClose, iFrameUrl } = props;

  const isTouch = useMediaQuery('screen and (pointer: coarse)');

  const [loading, setLoading] = useState<boolean>(true);
  const [playlistCollapsed, setPlaylistCollapsed] = useState<boolean>(false);
  const [iFrameExpanded, setIFrameExpanded] = useState<boolean>(false);

  const [currentPlaylistItem, setCurrentPlaylistItem] = useState<IFramePlayerModalPlaylistItem>();
  const [nextPlaylistItem, setNextPlaylistItem] = useState<IFramePlayerModalPlaylistItem>();
  const [playlist, setPlaylist] = useState<IFramePlayerModalPlaylistItem[]>([]);

  function setPlaylistItems(playlistItem: IFramePlayerModalPlaylistItem | undefined, playlist: IFramePlayerModalPlaylistItem[]) {
    setLoading(true);
    setTimeout(() => setLoading(false), 3000);

    setCurrentPlaylistItem(playlistItem);
    if (!playlistItem) return setNextPlaylistItem(undefined);

    const currentPlaylistItemIndex = playlist.findIndex(temp => temp.lessonUrl === playlistItem.lessonUrl);
    if (currentPlaylistItemIndex === -1) return setNextPlaylistItem(undefined);

    const nextPlaylistItem = playlist[currentPlaylistItemIndex + 1];
    setNextPlaylistItem(nextPlaylistItem?.locked ? undefined : nextPlaylistItem);
  }

  useEffect(() => {
    const defaultPlaylistItem = props.playlist.find(playlistItem => playlistItem.lessonUrl === iFrameUrl);
    setPlaylistItems(defaultPlaylistItem, props.playlist);
    setPlaylist(props.playlist);
  }, [props]);

  return !currentPlaylistItem ? (
    <React.Fragment />
  ) : (
    <FullscreenModal show={show} onClose={onClose} onNext={!nextPlaylistItem ? undefined : () => setPlaylistItems(nextPlaylistItem, playlist)} onReturn={!iFrameExpanded ? undefined : () => setIFrameExpanded(false)}>
      <div className={styles.player}>
        {isTouch ? (
          <React.Fragment>
            {iFrameExpanded ? (
              <div className={classNames(styles.iframe, { [styles.playlistCollapsed]: playlistCollapsed })}>
                <React.Fragment>
                  {loading ? (
                    <div className={iFrameStyles.loader}>
                      <div className={iFrameStyles.loaderContent}>
                        <LoaderIcon />
                        <h6 className="subtitle">Loading your learning content</h6>
                      </div>
                    </div>
                  ) : (
                    <iframe className={iFrameStyles.iFrame} src={generateIFrameUrl(currentPlaylistItem.lessonUrl)} frameBorder={0}></iframe>
                  )}
                </React.Fragment>
              </div>
            ) : (
              <button className={styles.thumbnailButton} onClick={() => setIFrameExpanded(true)}>
                {!!currentPlaylistItem.thumbnailUrl && (
                  <div className={styles.thumbnailFigure}>
                    <img src={currentPlaylistItem.thumbnailUrl} alt="Thumbnail" />
                  </div>
                )}

                <figure className={styles.thumbnailStartIcon}>
                  <StartIcon size={75} tone="contrast-text" />
                </figure>

                <div className={styles.thumbnailText}>{generateButtonText(currentPlaylistItem.completionStatus)}</div>
              </button>
            )}
          </React.Fragment>
        ) : (
          <div className={classNames(styles.iframe, { [styles.playlistCollapsed]: playlistCollapsed })}>
            <React.Fragment>
              {loading ? (
                <div className={iFrameStyles.loader}>
                  <div className={iFrameStyles.loaderContent}>
                    <LoaderIcon />
                    <h6 className="subtitle">Loading your learning content</h6>
                  </div>
                </div>
              ) : (
                <iframe className={iFrameStyles.iFrame} src={generateIFrameUrl(currentPlaylistItem.lessonUrl)} frameBorder={0}></iframe>
              )}
            </React.Fragment>
          </div>
        )}

        {!isTouch && (
          <button type="button" className={classNames(styles.playlistButton, { [styles.playlistCollapsed]: playlistCollapsed })} onClick={() => setPlaylistCollapsed(currentState => !currentState)}>
            <DoubleChevronIcon tone="text" size={10} rotation={playlistCollapsed ? 1 : 3} />
          </button>
        )}

        {(!isTouch || !iFrameExpanded) && (
          <div className={classNames(styles.playlist, { [styles.playlistCollapsed]: playlistCollapsed }, 'playlist')}>
            {playlist.map(playlistItem => (
              <div
                className={classNames('card', styles.playlistItem, { [styles.active]: currentPlaylistItem.lessonUrl === playlistItem.lessonUrl })}
                style={{ cursor: 'pointer' }}
                key={playlistItem.name}
                onClick={() =>
                  setPlaylist(currentState => {
                    const nextState: IFramePlayerModalPlaylistItem[] = structuredClone(currentState);
                    const temp = nextState.find(temp => temp.lessonUrl === playlistItem.lessonUrl);
                    if (!!temp) temp.expanded = !temp.expanded;
                    return nextState;
                  })
                }
              >
                <header style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span className="caption2" style={{ color: colourString('text_1') }}>
                    {playlistItem.type}&nbsp;
                  </span>

                  <span className="caption2" style={{ color: colourString('text_1') }}>
                    {generateCompletionStatusText(playlistItem.completionStatus)}
                  </span>
                </header>

                <h5 className={classNames('heading5', styles.cardName)}>{playlistItem.name}</h5>

                <p className={classNames('body2', styles.cardDescription)}>{playlistItem.description}</p>

                {playlistItem.expanded && (
                  <React.Fragment>
                    {playlistItem.locked ? (
                      <footer className={styles.cardFooter}>
                        <p className={classNames('body2', styles.cardFooterText)}>Locked Lesson</p>
                        <LockIcon tone="default" size={15} />
                      </footer>
                    ) : (
                      <footer className={styles.cardFooter}>
                        <Anchor
                          onClick={event => {
                            event.stopPropagation();
                            setPlaylistItems(playlistItem, playlist);
                          }}
                        >
                          {generateButtonText(playlistItem.completionStatus)}
                        </Anchor>
                      </footer>
                    )}
                  </React.Fragment>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </FullscreenModal>
  );
}
