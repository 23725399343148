import React, { useEffect, useState } from 'react';
import { ScoresSearchParams } from '../../../models/overmind/search-params';
import { useAppActions, useAppState } from '../../../overmind';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { EntityScores } from '../../../models/overmind/analytics';
import { CompanyVariables } from '../../../models/view/company-variables';
import { DataPoint } from '../../../components/charts/recharts.models';
import { RadarChartComponent } from './radar-chart/radar-chart';
import { OrganizationLevelType, ScoreType } from '../../../enums';
import { ScoreComparison } from '../../../enums/score-comparison';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import { removeDuplicateScores } from '../../../components/charts/recharts.helpers';

function transformScores(scores: EntityScores[], companyVariables: CompanyVariables): DataPoint[] {
  const uniqueScores = removeDuplicateScores(scores, 'name');

  return uniqueScores.map(score => ({
    name: score.name,
    ['Actual proficiency']: companyVariables.useLevels ? score.score?.level : score.score?.percentage,
    ['Expected proficiency']: score.comparisonScore ? (companyVariables.useLevels ? score.comparisonScore.level : score.comparisonScore.percentage) : undefined,
  }));
}

export function RoleProficiencyRadarWidget({ roleSlug }: { roleSlug: string | undefined }): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<DataPoint[]>();

  useEffect(() => {
    async function getData() {
      if (!roleSlug) return;
      setLoading(true);
      const request: ScoresSearchParams = {
        sortAscending: false,
        page: 1,
        pageSize: 10,
        startDate: undefined,
        endDate: undefined,
        organizationLevel: OrganizationLevelType.Company,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        scoreType: ScoreType.RoleSkill,
        scoreTypeSlugs: [roleSlug],
        scoreComparison: ScoreComparison.RoleExpectedProficiency,
      };

      const response = await actions.analyticsGetScoresByScoreType(request);
      setData(transformScores(response?.results ?? [], companyVariables));
      setLoading(false);
    }

    getData();
  }, [roleSlug]);

  return (
    loading ? (
      <SkeletonLoader height="400" />
    ) : (
      <div className="card">
        <h4 className="heading4" style={{ marginBottom: 20 }}>
          Role proficiency radar
        </h4>
        {!!data && data?.length > 2 ?
          <RadarChartComponent data={data} /> :
          <EmptyState
            badgeUrl={themedAssetUrl('graphics/empty-state-clouds.graphic.svg')}
            badgeIconWidth="full-width"
            title="Not enough data"
            centerText
          />
        }
      </div>
    )
  );
}
