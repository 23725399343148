/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { PagePath } from '../../../../navigation/navigation.enums';
import { PageHeader } from '@keplerco/core';
import { useAppActions, useAppState } from '../../../../overmind';
import { Stepper } from '../../../../components/general/stepper/stepper';
import { PeopleStepLayout } from './people-step/people-step.layout';
import { AssessmentType } from '../../../../enums/assessment-type';
import { PageLoaderLayer } from '../../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { ActivateStepLayout } from './activate-step/activate-step.layout';
import { SkillsStepLayout } from './skills-step/skills-step.layout';
import { IPerson } from '../../../../widgets/forms/skill-assessment/people-and-skills/people-and-skills.models';
import { AssessmentPreferencesResponse } from '../../../../models/overmind/assessment-preferences';
import { DetailsStepLayout } from './details-step/details-step.layout';
import { useParams } from 'react-router-dom';
import { Step } from '../../../../components/general/stepper/step/step';
import { useStepperConnector } from '../../../../components/general/stepper/use-stepper-connector';
import { DEFAULT_PREFERENCES, Details, StepIds } from './assessments-wizard-peer.models';

export function AssessmentsWizardPeerPage(): JSX.Element {
  const params = useParams();

  const { companyVariables } = useAppState();
  const actions = useAppActions();

  const [assessmentSlug, setAssessmentSlug] = useState<string | undefined>(params.assessmentSlug);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<Details>();
  const [preferences, setPreferences] = useState<AssessmentPreferencesResponse>(DEFAULT_PREFERENCES);
  const [people, setPeople] = useState<IPerson[]>();
  const [roleSlug, setRoleSlug] = useState<string | undefined>();

  const stepperConnector = useStepperConnector();

  useEffect(() => {
    async function getDetails() {
      if (!assessmentSlug) return;
      const response = await actions.getAssessmentDetails(assessmentSlug);
      setDetails(
        !!response
          ? {
              name: response.name,
              keplerPoints: response.keplerPointsAvailable,
            }
          : undefined
      );
    }

    async function getPreferences() {
      if (!assessmentSlug) return;
      const response = await actions.getAssessmentPreferences({ assessmentSlug, companySlug: companyVariables.slug! });
      setPreferences(response ?? DEFAULT_PREFERENCES);
    }

    async function getPeopleAndSkills() {
      if (!assessmentSlug) return;

      const employeesData = await actions.searchPeople({
        companySlug: companyVariables.slug,
        page: 1,
        pageSize: 99999,
      });
      const assigneesData = await actions.getSkillAssessmentAssignees({ companySlug: companyVariables.slug!, skillAssessmentSlug: assessmentSlug });

      const peopleData: IPerson[] = [];
      assigneesData?.assignees?.forEach(assignee => {
        const person = employeesData?.employees.find(employee => employee.id === assignee.userId);
        if (!person) return;
        peopleData.push({
          ...person,
          selected: true,
          manager: person.id === assigneesData.assignedTeamChampionId,
          skills:
            assignee.skills?.map(skill => ({
              slug: skill.companySkillId.toString(),
              name: skill.skillName,
              description: skill.skillDescription,
              level: skill.skillLevel,
              companyEntityId: skill.companySkillId,
            })) ?? [],
        });
      });
      setPeople(peopleData);
    }

    async function getData() {
      if (!assessmentSlug) return;

      setIsLoading(true);

      await getDetails();
      await getPreferences();
      await getPeopleAndSkills();

      setIsLoading(false);
    }

    getData();
  }, [assessmentSlug]);

  return (
    <PageLoaderLayer path={PagePath.assessmentManagementWizardPeer}>
      <div className="wrapper stack" style={{ paddingBottom: 24, gap: 24 }}>
        <PageHeader
          breadcrumbs={[
            { name: 'Assessment Management', url: `${PagePath.assessmentManagementBase}` },
            { name: 'Wizard', url: `${PagePath.assessmentManagementBase}${PagePath.assessmentManagementWizard.replace(':companySlug', companyVariables.slug!)}` },
            { name: 'Peer Assessment', url: `${PagePath.assessmentManagementBase}${PagePath.assessmentManagementWizardPeer.replace(':companySlug', companyVariables.slug!)}` },
          ]}
          title="Peer Assessment"
          subtitle="Create and customise assessments by selecting skills, roles, and criteria for evaluation"
          divider
        />

        <Stepper {...stepperConnector}>
          <Step key={StepIds.Details} label={StepIds.Details} completed={!!assessmentSlug} required>
            <DetailsStepLayout
              assessmentType={AssessmentType.PeerEndorsement}
              assessmentSlug={assessmentSlug}
              setAssessmentSlug={setAssessmentSlug}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              details={details}
              setDetails={setDetails}
              preferences={preferences}
              setPreferences={setPreferences}
              onNext={stepperConnector.next}
            />
          </Step>

          <Step key={StepIds.People} label={StepIds.People} completed={!!people?.length} required>
            <PeopleStepLayout assessmentSlug={assessmentSlug!} isLoading={isLoading} setIsLoading={setIsLoading} preferences={preferences} people={people} setPeople={setPeople} setRoleSlug={setRoleSlug} onBack={stepperConnector.previous} onNext={stepperConnector.next} />
          </Step>

          <Step key={StepIds.Skills} label={StepIds.Skills} completed={!!people?.length && people.every(person => !!person.skills.length)} required>
            <SkillsStepLayout assessmentSlug={assessmentSlug!} isLoading={isLoading} setIsLoading={setIsLoading} preferences={preferences} people={people} setPeople={setPeople} roleSlug={roleSlug} onBack={stepperConnector.previous} onNext={stepperConnector.next} />
          </Step>

          {/* TODO: <Step key={StepId.Activate} label={StepId.Activate} completed={assessment.active}> */}
          <Step key={StepIds.Activate} label={StepIds.Activate}>
            <ActivateStepLayout assessmentSlug={assessmentSlug!} isLoading={isLoading} setIsLoading={setIsLoading} onBack={stepperConnector.previous} />
          </Step>
        </Stepper>
      </div>
    </PageLoaderLayer>
  );
}
