/* eslint-disable indent */
import { Anchor, Button, CheckboxCard, PanelHeader, useMatchScreenWidth } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { ListItem } from '../../../../../../components/lists/list-item';
import { ActivationStatus, OrganizationLevelType } from '../../../../../../enums';
import { useAppActions, useAppState } from '../../../../../../overmind';
import { CompanyRoleAssignee } from '../people-step.models';
import { PagingSearchParams, SearchingSearchParams, SortingSearchParams } from '../../../../../../models/overmind/search-params';
import { CohortType } from '../../../../../../components/panels/select-cohort/select-cohort.models';
import { UniqueEntity } from '../../../../../../models';
import { SearchList } from '../../../../../../components/lists/search-list';
import { ISelectPeopleLayoutProps } from './select-people.models';
import { AstronautLoader } from '../../../../../../components/general/loading-state/loaders/astronaut-loader/astronaut-loader';

// TODO: refactor Roles/Assessments so that we can use the same Select People Layout
export function SelectPeopleLayout({ selectedCohortType, selectedEntity, selectedPeople, onBack, onDone }: ISelectPeopleLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [request, setRequest] = useState<SearchingSearchParams & SortingSearchParams & PagingSearchParams>({
    search: undefined,
    sortAscending: true,
    page: 1,
    pageSize: 99999, // TODO: get correct size
  });
  const [peopleToSelect, setPeopleToSelect] = useState<CompanyRoleAssignee[]>(selectedPeople);
  const [people, setPeople] = useState<CompanyRoleAssignee[]>();

  const isMobile = useMatchScreenWidth('mobile');

  async function getDepartmentPeople(request: SearchingSearchParams & SortingSearchParams & PagingSearchParams, entity: UniqueEntity | undefined) {
    const response = await actions.searchPeople({ ...request, companySlug: companyVariables.slug, departmentSlug: entity?.slug });
    setPeople(
      response?.employees
        .filter(employee => employee.activationStatus !== ActivationStatus.Archived)
        .map(employee => ({
          entitySlug: employee.learnerSlug,
          name: `${employee.firstName} ${employee.lastName}`,
          email: employee.email,
          department: employee.department.name,
          team: employee.team.name,
          organizationLevel: OrganizationLevelType.Learner,
          selected: selectedPeople.some(selectedPerson => selectedPerson.entitySlug === employee.learnerSlug) || peopleToSelect.some(personToSelect => personToSelect.entitySlug === employee.learnerSlug),
        })) ?? []
    );
    return;
  }
  async function getTeamPeople(request: SearchingSearchParams & SortingSearchParams & PagingSearchParams, entity: UniqueEntity | undefined) {
    const response = await actions.searchPeople({ ...request, companySlug: companyVariables.slug, teamSlug: entity?.slug });
    setPeople(
      response?.employees
        .filter(employee => employee.activationStatus !== ActivationStatus.Archived)
        .map(employee => ({
          entitySlug: employee.learnerSlug,
          name: `${employee.firstName} ${employee.lastName}`,
          email: employee.email,
          department: employee.department.name,
          team: employee.team.name,
          organizationLevel: OrganizationLevelType.Learner,
          selected: selectedPeople.some(selectedPerson => selectedPerson.entitySlug === employee.learnerSlug) || peopleToSelect.some(personToSelect => personToSelect.entitySlug === employee.learnerSlug),
        })) ?? []
    );
    return;
  }

  async function getPeople(request: SearchingSearchParams & SortingSearchParams & PagingSearchParams) {
    const response = await actions.searchPeople({ ...request, companySlug: companyVariables.slug });
    setPeople(
      response?.employees
        .filter(employee => employee.activationStatus !== ActivationStatus.Archived)
        .map(employee => ({
          entitySlug: employee.learnerSlug,
          name: `${employee.firstName} ${employee.lastName}`,
          email: employee.email,
          department: employee.department.name,
          team: employee.team.name,
          organizationLevel: OrganizationLevelType.Learner,
          selected: selectedPeople.some(selectedPerson => selectedPerson.entitySlug === employee.learnerSlug) || peopleToSelect.some(personToSelect => personToSelect.entitySlug === employee.learnerSlug),
        })) ?? []
    );
  }

  async function getPeopleData(request: SearchingSearchParams & SortingSearchParams & PagingSearchParams) {
    switch (selectedCohortType) {
      case CohortType.Department: {
        await getDepartmentPeople(request, selectedEntity);
        break;
      }

      case CohortType.Team: {
        await getTeamPeople(request, selectedEntity);
        break;
      }

      case CohortType.People: {
        await getPeople(request);
        break;
      }

      default: {
        break;
      }
    }
  }

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      await getPeopleData(request);
      setIsLoading(false);
    }

    getData();
  }, [selectedCohortType, selectedEntity]);

  return (
    <div className="panelContent">
      <PanelHeader supertitle="Roles" title={`${selectedEntity?.name ?? 'Select People'}`} subtitle="Select the people that you want to assign to this role." />

      <section className="card" style={{ display: 'flex', flexDirection: 'column' }}>
        {isLoading ? (
          <AstronautLoader />
        ) : (
          <SearchList
            loading={isSearching}
            onInput={async search => {
              setIsSearching(true);
              const nextRequest = { ...request, search, page: 1 };
              setRequest(nextRequest);
              await getPeopleData(nextRequest);
              setIsSearching(false);
            }}
            actions={
              <div style={{ width: 120 }}>
                <CheckboxCard
                  id="selectAll"
                  checked={!people?.some(person => !person.selected) && !!people?.length}
                  onChange={() => {
                    const allSelected = people?.every(person => person.selected);

                    setPeople(currentState => {
                      if (!currentState) return;
                      const nextState: CompanyRoleAssignee[] = structuredClone(currentState);
                      nextState.forEach(nextPerson => (nextPerson.selected = !allSelected));
                      return nextState;
                    });

                    setPeopleToSelect(currentState => {
                      if (allSelected) return [];

                      const nextState: CompanyRoleAssignee[] = structuredClone(currentState);
                      people?.forEach(person => {
                        const nextPerson = nextState.find(temp => temp.entitySlug === person.entitySlug);
                        if (!nextPerson) nextState.push({ ...person, selected: true });
                      });
                      return nextState;
                    });
                  }}
                >
                  <div className="card">Select all</div>
                </CheckboxCard>
              </div>
            }
            emptyState
          >
            {people?.map(person => {
              return (
                <ListItem
                  key={person.entitySlug.toString()}
                  selected={person.selected}
                  onClick={() => {
                    const selected = !person.selected;

                    setPeople(currentState => {
                      if (!currentState) return;
                      const nextState: CompanyRoleAssignee[] = structuredClone(currentState);
                      const nextPerson = nextState.find(temp => temp.entitySlug === person.entitySlug);
                      if (!!nextPerson) {
                        nextPerson.selected = selected;
                      }
                      return nextState;
                    });

                    setPeopleToSelect(currentState => {
                      let nextState: CompanyRoleAssignee[] = structuredClone(currentState);
                      selected
                        ? nextState.push({
                            ...person,
                            selected,
                          })
                        : (nextState = nextState.filter(temp => temp.entitySlug !== person.entitySlug));
                      return nextState;
                    });
                  }}
                >
                  <div style={{ display: 'grid', gap: 15, alignItems: 'center', gridTemplateColumns: isMobile ? 'auto' : '1fr 1fr' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: isMobile ? 5 : 0 }}>
                      <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                        {person.name}
                      </div>

                      <div className="caption2">
                        {person.department} | {person.team}
                      </div>
                    </div>

                    <div className="caption2">{person.email}</div>
                  </div>
                </ListItem>
              );
            })}
          </SearchList>
        )}
      </section>

      <footer className="panelFooter">
        <Anchor onClick={onBack}>Back</Anchor>

        <Button type="button" onClick={() => onDone(peopleToSelect)}>
          Done
        </Button>
      </footer>
    </div>
  );
}
