import React, { useEffect, useState } from 'react';
import { FocusPanelLoaderLayer } from '../../../../../../components/general/loading-state/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { OrganizationLevelType } from '../../../../../../enums';
import { ManagePersonDetailsLayout } from './manage-person-details.layout';
import { IManagePersonFocusPanelProps, ManagePersonCascadingPanelIds } from '../manage-person-panels.models';
import { PagePath } from '../../../../../../navigation/navigation.enums';
import { useAppState } from '../../../../../../overmind';
import { extractHighestOrganizationLevel } from '../../../../../../library/helpers/permissions/extract-highest-organization-level';
import { OrganizationLevel } from '../../../../../../models/overmind/permissions';
import { Anchor, AvatarIcon, ChevronIcon, Chip, ListItemLayout, ListLayout, useMatchScreenWidth } from '@keplerco/core';
import managePersonPanelsStyles from '../manage-person-panels.module.css';

export function ManagePersonFocusPanel(props: IManagePersonFocusPanelProps): JSX.Element {
  const { user } = useAppState();
  const isMobile = useMatchScreenWidth('mobile');

  const [hasPermissions, setHasPermissions] = useState<boolean>(false);

  function isLearner(organizationLevels: OrganizationLevel[]): boolean {
    const organizationLevel = extractHighestOrganizationLevel(organizationLevels);
    if (!organizationLevel) return true;
    return organizationLevel.organizationLevel === OrganizationLevelType.Learner;
  }

  useEffect(() => {
    if (!props.permissions) return setHasPermissions(false);

    if (!isLearner(props.permissions.administration.organizationLevels)) return setHasPermissions(true);
    if (!isLearner(props.permissions.analytics.organizationLevels)) return setHasPermissions(true);
    if (!isLearner(props.permissions.assessments.organizationLevels)) return setHasPermissions(true);
    if (!isLearner(props.permissions.learningManagement.organizationLevels)) return setHasPermissions(true);
    if (!isLearner(props.permissions.roleSkillManagement.organizationLevels)) return setHasPermissions(true);

    return setHasPermissions(false);
  }, [props.permissions]);

  function generatePermissionsChip(): JSX.Element {
    return hasPermissions ? <Chip label="Permissions assigned" backgroundColour="apple" /> : <Chip label="No permissions assigned" />;
  }

  function generateTagsChip(): JSX.Element {
    return !!props.tags?.length ? <Chip label="Tags assigned" backgroundColour="apple" /> : <Chip label="No tags assigned" />;
  }

  return (
    <FocusPanelLoaderLayer path={PagePath.administrationPeople}>
      <div className="panelContent">
        <header className={managePersonPanelsStyles.header}>
          <div className={managePersonPanelsStyles.avatar}>
            <AvatarIcon name={props.person} />
          </div>

          {!!props.person ? (
            <div>
              <h3 className="heading3">
                {props.person.firstName} {props.person.lastName}
              </h3>

              <span className="caption1">
                <strong>Department</strong> {props.person.departmentName} | <strong>Team</strong> {props.person.teamName}
              </span>
            </div>
          ) : (
            <div>
              <h3 className="heading3">Add a new person</h3>
            </div>
          )}
        </header>

        <ListLayout>
          <ListItemLayout>
            <ManagePersonDetailsLayout person={props.person} onSubmit={props.onSave} />
          </ListItemLayout>

          {!!props.person?.id && (
            <React.Fragment>
              {user?.isSystemAdmin && (
                <ListItemLayout onClick={() => props.onClickSection(ManagePersonCascadingPanelIds.Permissions)}>
                  <div className="card" style={{ display: 'grid', gridTemplateColumns: `1fr ${!isMobile ? 'auto' : ''} auto`, alignItems: 'center', gap: 15 }}>
                    Permissions
                    {!isMobile && generatePermissionsChip()}
                    <ChevronIcon tone="primary" />
                  </div>
                </ListItemLayout>
              )}

              <ListItemLayout onClick={() => props.onClickSection(ManagePersonCascadingPanelIds.Tags)}>
                <div className="card" style={{ display: 'grid', gridTemplateColumns: `1fr ${!isMobile ? 'auto' : ''} auto`, alignItems: 'center', gap: 15 }}>
                  Tags
                  {!isMobile && generateTagsChip()}
                  <ChevronIcon tone="primary" />
                </div>
              </ListItemLayout>
            </React.Fragment>
          )}
        </ListLayout>

        <footer className="panelFooter">
          <Anchor onClick={props.onCancel}>Cancel</Anchor>
        </footer>
      </div>
    </FocusPanelLoaderLayer>
  );
}
