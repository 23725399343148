import { Context } from '../..';
import * as base from '../base';
import { NotificationPriority, NotificationType } from '../../../notifications/notifications.models';
import {
  AssignCompanyEntityRequest,
  CompanyEntityAssigneeListResponse,
  ImportCompanyEntityRequest,
  CreateCompanyEntityRequest,
  UpdateCompanyEntityRequest,
  CompanyRoleListResponse,
  CompanyRoleListItemResponse,
  SkillToRoleRelationRequest,
  ExpectedLevelToRoleSkillRelationRequest,
  AddSkillToRoleRelationRequest,
} from '../../../models/overmind/company-entity';
import { CompanyEntitySearchParams, CompanyRoleAssigneesSearchParams } from '../../../models/overmind/search-params';
import { PagePath } from '../../../navigation/navigation.enums';
import { NavigateOptions } from 'react-router-dom';

const CONTROLLER = `companyrole`;

export async function activateCompanyRole(context: Context, payload: { companySlug: string; roleSlug: string; keplerNavigate?: (to: string, options?: NavigateOptions) => void }): Promise<string | undefined> {
  const url: string = base.apiURL(CONTROLLER, 'activate', payload.companySlug, payload.roleSlug);
  const request: base.IRequest = { url, method: 'POST' };
  const response: base.IResponse<string> = await base.request(request);

  if (response.error?.status === 400) {
    context.actions.addNotification({
      title: response.error.title || 'There are no skills added to this role',
      message: response.error.detail || `Would you like to do that now?`,
      priority: NotificationPriority.Toast,
      type: NotificationType.Info,
      id: crypto.randomUUID(),
      active: true,
      toastIncludeIcon: true,
      toastButton: {
        onClick: () => payload.keplerNavigate && payload.keplerNavigate(`${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementWizardUpdate.replace(':companySlug', payload.companySlug).replace(':roleSlug', payload.roleSlug)}`),
        text: 'Add skills',
      },
    });
  } else if (response.error) {
    context.actions.addNotification({
      title: 'There was an error activating this role',
      message: response.error.detail || `Please check all the fields are correct`,
      priority: NotificationPriority.Toast,
      type: NotificationType.Error,
      id: crypto.randomUUID(),
      active: true,
      toastIncludeIcon: true,
    });
  } else {
    context.actions.addNotification({
      title: 'The role was successfully activated!',
      priority: NotificationPriority.Toast,
      type: NotificationType.Success,
      id: crypto.randomUUID(),
      active: true,
      toastIncludeIcon: true,
    });
  }

  return response.data;
}

export async function createCompanyRole(context: Context, payload: CreateCompanyEntityRequest): Promise<string | undefined> {
  const url: string = base.apiURL('companyrole', 'create-role', payload.companySlug);
  const request: base.IRequest = { url, method: 'POST', body: JSON.stringify(payload) };
  const response: base.IResponse<string> = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      title: 'There was an error creating this role',
      message: response.error.detail,
      priority: NotificationPriority.Toast,
      type: NotificationType.Error,
      id: crypto.randomUUID(),
      active: true,
      toastIncludeIcon: true,
    });
  }

  return response.data;
}

export async function updateCompanyRole(context: Context, body: UpdateCompanyEntityRequest) {
  const url: string = base.apiURL('companyrole', 'update-role', body.companySlug, body.entitySlug);
  const request: base.IRequest = { url, method: 'PUT', body: JSON.stringify(body) };
  const response = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      title: 'There was an error updating this role',
      message: `There was an error updating this role`,
      priority: NotificationPriority.Toast,
      type: NotificationType.Error,
      id: crypto.randomUUID(),
      active: true,
    });
  }
}

export async function importCompanyRole(context: Context, payload: ImportCompanyEntityRequest): Promise<base.IResponse<string>> {
  const url: string = base.apiURL(CONTROLLER, `import`, payload.companySlug, payload.entitySlug);
  const request: base.IRequest = { url, method: 'POST' };
  const response: base.IResponse<string> = await base.request(request);
  return response;
}

export async function assignCompanyRole(context: Context, body: AssignCompanyEntityRequest): Promise<void> {
  const url: string = base.apiURL('companyrole', 'assign-role', context.state.companyVariables.slug!);
  const pulledRoleAssignment: AssignCompanyEntityRequest = { entitySlug: body.entitySlug, assignees: body.assignees };
  const request: base.IRequest = { url, method: 'POST', body: JSON.stringify(pulledRoleAssignment) };
  await base.request(request);
}

export async function unassignCompanyRole(context: Context, body: AssignCompanyEntityRequest): Promise<void> {
  const url: string = base.apiURL('companyrole', 'unassign-role', context.state.companyVariables.slug!);
  const request: base.IRequest = { url, method: 'PUT', body: JSON.stringify(body) };
  await base.request(request);
}

/**
 * Search for Roles as a User within the User's Company.
 */
export async function getUserCompanyRoleAssignees(context: Context, payload: CompanyRoleAssigneesSearchParams): Promise<CompanyEntityAssigneeListResponse | undefined> {
  const url: string = base.apiURL(CONTROLLER, `get-role-assignees`, `${payload.roleSlug}${base.params(payload)}`);
  const request: base.IRequest = { url };
  const response: base.IResponse<CompanyEntityAssigneeListResponse> = await base.request(request);
  return response.data;
}

/**
 * Search for Role Assignees as a User with Permissions or a User with System Admin within any Company.
 */
export async function getCompanyRoleAssignees(context: Context, payload: CompanyRoleAssigneesSearchParams): Promise<CompanyEntityAssigneeListResponse | undefined> {
  const url: string = base.apiURL(CONTROLLER, `get-role-assignees`, `${payload.companySlug}/${payload.roleSlug}${base.params(payload)}`);
  const request: base.IRequest = { url };
  const response: base.IResponse<CompanyEntityAssigneeListResponse> = await base.request(request);
  return response.data;
}

// REFACTORED
/**
 * Search for Roles as a User within the User's Company.
 */
export async function getUserCompanyRoles(context: Context, payload: CompanyEntitySearchParams): Promise<CompanyRoleListResponse | undefined> {
  const url: string = base.apiURL(CONTROLLER, `get-roles${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<CompanyRoleListResponse> = await base.request(request);
  return response.data;
}

/**
 * Search for Roles as a User with Permissions or a User with System Admin within any Company.
 */
export async function getCompanyRoles(context: Context, payload: CompanyEntitySearchParams): Promise<CompanyRoleListResponse | undefined> {
  const url: string = base.apiURL(CONTROLLER, `get-roles`, `${payload.companySlug}${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<CompanyRoleListResponse> = await base.request(request);
  return response.data;
}

/**
 * Request a Role as a User from within the User's Company.
 * If the Role is not saved to the User's Company this request will result in a 404.
 */
export async function getUserCompanyRole(context: Context, payload: { roleSlug: string }): Promise<CompanyRoleListItemResponse | undefined> {
  const url: string = base.apiURL('companyrole', 'get-role', payload.roleSlug);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<CompanyRoleListItemResponse> = await base.request(request);
  return response.data;
}

/**
 * Request a Role as a User with Permissions or a User with System Admin withing any Company.
 */
export async function getCompanyRole(context: Context, payload: { companySlug: string; roleSlug: string }): Promise<CompanyRoleListItemResponse | undefined> {
  const url: string = base.apiURL('companyrole', 'get-role', payload.companySlug, payload.roleSlug);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<CompanyRoleListItemResponse> = await base.request(request);
  return response.data;
}

export async function addSkillToRoleRelation(context: Context, payload: AddSkillToRoleRelationRequest): Promise<string | undefined> {
  const url: string = base.apiURL('companyrole', 'relate', context.state.companyVariables.slug!);
  const request: base.IRequest = { url, method: 'POST', body: JSON.stringify(payload) };
  const response: base.IResponse<string> = await base.request(request);
  return response.data;
}

export async function removeSkillToRoleRelation(context: Context, payload: SkillToRoleRelationRequest): Promise<void> {
  const url: string = base.apiURL('companyrole', 'un-relate', context.state.companyVariables.slug!);
  const request: base.IRequest = { url, method: 'POST', body: JSON.stringify(payload) };
  await base.request(request);
}

export async function addExpectedLevelToRoleSkillRelation(context: Context, payload: ExpectedLevelToRoleSkillRelationRequest): Promise<void> {
  const url: string = base.apiURL('companyrole', 'relate', 'expected-level', context.state.companyVariables.slug!);
  const request: base.IRequest = { url, method: 'PUT', body: JSON.stringify(payload) };
  await base.request(request);
}
