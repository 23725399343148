import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppActions, useAppState } from '../../../overmind';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { KeplerState } from '../../../models/kepler-state';
import { AuthenticationStatus as STATUS } from '../../../enums/authentication-status';
import { SSOButton } from '../sso-button/sso-button';
import { AccountPageLoaderLayer } from '../../../components/general/loading-state/loader-layers/account-page-loader-layer/account-page-loader-layer';
import { FetchType } from '../../../enums';
import { getSubdomain } from '../../../library/helpers/get-subdomain';
import { PagePath } from '../../../navigation/navigation.enums';
import WeglotLanguageSwitcher from '../../../components/general/weglot/weglot-switcher';
import { Button, EmailValidator, FieldController, RequiredValidator, TextField, useMatchScreenWidth } from '@keplerco/core';
import accountStyles from '../account.module.css';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import classNames from 'classnames';
import { TertiaryButton } from '../../../components/general/tertiary-button/tertiary-button';

export function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');

  const actions = useAppActions();
  const { authenticationStatus } = useAppState<KeplerState>();

  const { control, handleSubmit } = useForm<any>({ reValidateMode: 'onChange', mode: 'onBlur' });

  const isMobile = useMatchScreenWidth('mobile');

  const [allowCreateAccount, setAllowCreateAccount] = useState<boolean>();
  const [samlUrl, setSamlUrl] = useState<string>();
  const [removeErrorMessage, setRemoveErrorMessage] = useState<boolean>(true);

  useEffect(() => {
    async function getData() {
      setAllowCreateAccount(await actions.getAllowCreateAccount());
      setSamlUrl(await actions.getSamlUrl(getSubdomain()));
    }

    getData();
  }, []);

  return (
    <>
      <div className={accountStyles.accountBackground} style={{ backgroundImage: `url(${themedAssetUrl('backgrounds/pattern.background.svg')})` }}>
        <div className={accountStyles.accountPage}>
          <div className={classNames('card', accountStyles.accountCard)} style={{ overflow: 'hidden' }}>
            <AccountPageLoaderLayer path={PagePath.accountLogin}>
              {!isMobile && (
                <div className={accountStyles.accountNavigationToggler}>
                  {allowCreateAccount ? (
                    <Fragment>
                      <span className="caption1">Not registered?</span>
                      {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                      <TertiaryButton type="button" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountRegister}`)}>
                        Create an account
                      </TertiaryButton>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <span className="caption1">Not registered?</span>
                      {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                      <TertiaryButton type="button" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountActivate}`)}>
                        Activate your account
                      </TertiaryButton>
                    </Fragment>
                  )}
                </div>
              )}

              <h1 className="heading1">Sign In</h1>

              <h6 className="subtitle">Fill in your company email address and your password</h6>

              <form
                id="login"
                className={accountStyles.accountForm}
                autoComplete="off"
                onSubmit={handleSubmit(async value => {
                  actions.startLoader({ path: PagePath.accountLogin, type: FetchType.PageFetching });
                  window.scrollTo(0, 0);
                  setRemoveErrorMessage(false);
                  await actions.login(value);
                  actions.stopLoader(PagePath.accountLogin);
                })}
              >
                {authenticationStatus === STATUS.AuthenticationFailed && !removeErrorMessage && <div className="formErrorMessage">Incorrect password or email address</div>}

                <FieldController
                  name="email"
                  control={control}
                  rules={new EmailValidator('Enter your email')}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Email"
                      type="text"
                      validation={{
                        dirty: fieldState.isDirty || !!fieldState.error,
                        invalid: !!fieldState.error,
                        message: fieldState.error?.message ?? 'Enter your email',
                      }}
                      responsive
                    />
                  )}
                />

                <FieldController
                  name="password"
                  control={control}
                  rules={new RequiredValidator('Enter your password')}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Password"
                      type="password"
                      validation={{
                        dirty: fieldState.isDirty || !!fieldState.error,
                        invalid: !!fieldState.error,
                        message: fieldState.error?.message ?? 'Enter your password',
                      }}
                      responsive
                    />
                  )} />

                {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                <TertiaryButton type="button" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountForgotPassword}`)} style={{ marginLeft: 'auto' }}>
                  Forgot password?
                </TertiaryButton>

                <Button fullWidthMobile={true} type="button">
                  Sign In
                </Button>
              </form>

              <div className={accountStyles.accountDivider}>Or sign in using</div>

              {!!samlUrl && (
                <SSOButton
                  ssoType="saml"
                  onClick={async () => {
                    window.location.href = !!returnTo ? `${samlUrl}?returnUrl=${returnTo}` : samlUrl;
                  }}
                />
              )}

              <SSOButton
                ssoType="google"
                onClick={async () => {
                  actions.startLoader({ path: PagePath.accountLogin, type: FetchType.PageFetching });
                  await actions.externalLogin({ provider: 'Google' });
                  actions.stopLoader(PagePath.accountLogin);
                }}
              />

              <SSOButton
                ssoType="microsoft"
                onClick={async () => {
                  actions.startLoader({ path: PagePath.accountLogin, type: FetchType.PageFetching });
                  await actions.externalLogin({ provider: 'Microsoft' });
                  actions.stopLoader(PagePath.accountLogin);
                }}
              />

              <SSOButton
                ssoType="linkedin"
                onClick={async () => {
                  actions.startLoader({ path: PagePath.accountLogin, type: FetchType.PageFetching });
                  await actions.externalLogin({ provider: 'LinkedIn' });
                  actions.stopLoader(PagePath.accountLogin);
                }}
              />

              {isMobile && (
                <div className={classNames(accountStyles.accountNavigationToggler, accountStyles.bottom)}>
                  {allowCreateAccount ? (
                    <Fragment>
                      <span className="caption1">Not registered?</span>
                      {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                      <TertiaryButton type="button" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountRegister}`)}>
                        Create an account
                      </TertiaryButton>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <span className="caption1">Not registered?</span>
                      {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                      <TertiaryButton type="button" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountActivate}`)}>
                        Activate your account
                      </TertiaryButton>
                    </Fragment>
                  )}
                </div>
              )}
            </AccountPageLoaderLayer>
          </div>
        </div>
      </div>

      <WeglotLanguageSwitcher position="bottomLeft" />
    </>
  );
}
