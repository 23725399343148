import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppActions, useAppState } from '../../../overmind';
import { useNavigate } from 'react-router-dom';
import { KeplerState } from '../../../models/kepler-state';
import { SSOButton } from '../sso-button/sso-button';
import { ResetPassword } from '../../../models/view/reset-password';
import { AccountPageLoaderLayer } from '../../../components/general/loading-state/loader-layers/account-page-loader-layer/account-page-loader-layer';
import { FetchType } from '../../../enums';
import { getSubdomain } from '../../../library/helpers/get-subdomain';
import { PagePath } from '../../../navigation/navigation.enums';
import { Button, Checkbox, ConfirmationPasswordValidator, EmailValidator, FieldController, RequiredValidator, TextField, useMatchScreenWidth } from '@keplerco/core';
import { PasswordStrength } from '../password-strength/password-strength';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import accountStyles from '../account.module.css';
import classNames from 'classnames';
import { TertiaryButton } from '../../../components/general/tertiary-button/tertiary-button';

export function SetPasswordPage(): JSX.Element {
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const actions = useAppActions();
  const { passwordErrors } = useAppState<KeplerState>();

  const { control, handleSubmit, getValues, watch, setError } = useForm<any>({ reValidateMode: 'onChange' });

  const isMobile = useMatchScreenWidth('mobile');

  const [formValue, setFormValue] = useState<{ [key: string]: any }>();
  const [samlUrl, setSamlUrl] = useState<string | undefined>();

  useEffect(() => {
    watch(value => setFormValue(value));

    actions.getSamlUrl(getSubdomain()).then(response => {
      setSamlUrl(response);
    });
  }, []);

  return (
    <div className={accountStyles.accountBackground} style={{ backgroundImage: `url(${themedAssetUrl('backgrounds/pattern.background.svg')})` }}>
      <div className={accountStyles.accountPage}>
        <div className={classNames('card', accountStyles.accountCard)}>
          <AccountPageLoaderLayer path={PagePath.accountSetPassword}>
            {!isMobile && (
              <div className={accountStyles.accountNavigationToggler}>
                <span className="caption1">Already have an account?</span>
                {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                <TertiaryButton type="button" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountLogin}`)}>
                  Sign in
                </TertiaryButton>
              </div>
            )}

            <h1 className="heading1" id="set-password-heading">
              Welcome to Kepler
            </h1>
            <h6 className="subtitle">Fill in your company email address and set your password</h6>

            <form
              id="setPassword"
              className={accountStyles.accountForm}
              autoComplete="off"
              onSubmit={handleSubmit(async (value: ResetPassword) => {
                actions.startLoader({ path: PagePath.accountSetPassword, type: FetchType.PageFetching });
                value.code = params.get('code')?.toString();
                await actions.setPasswordAndLogin(value);
                actions.stopLoader(PagePath.accountSetPassword);
              })}
            >
              <FieldController
                control={control}
                name="email"
                rules={new EmailValidator('Enter your email')}
                render={({ field, fieldState }) => (
                  <TextField
                    type="email"
                    {...field}
                    validation={{
                      dirty: fieldState.isDirty || !!fieldState.error,
                      invalid: !!fieldState.error,
                      message: fieldState.error?.message ?? 'Enter your email',
                    }}
                    label="Email"
                    responsive
                  />
                )}
              />

              <FieldController
                control={control}
                name="password"
                rules={new RequiredValidator('Enter your password')}
                render={({ field, fieldState }) => (
                  <React.Fragment>
                    <TextField
                      {...field}
                      validation={{
                        dirty: fieldState.isDirty || !!fieldState.error,
                        invalid: !!fieldState.error,
                        message: fieldState.error?.message ?? 'Enter your password',
                      }}
                      label="Password"
                      type="password"
                      responsive
                    />
                    {!!formValue?.password && <div style={{ width: '100%' }}>
                      <PasswordStrength password={formValue.password} /></div>}
                  </React.Fragment>
                )}
              />

              <FieldController
                control={control}
                name="confirmPassword"
                rules={new ConfirmationPasswordValidator('Enter your confirmation password', getValues('password'))}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    validation={{
                      dirty: fieldState.isDirty || !!fieldState.error,
                      invalid: !!fieldState.error,
                      message: fieldState.error?.message ?? 'Enter your confirmation password',
                    }}
                    label="Confirm Password"
                    type="password"
                    responsive
                  />
                )}
              />

              <FieldController
                control={control}
                name="terms"
                rules={new RequiredValidator(true)}
                render={({ field, fieldState }) => (
                  <React.Fragment>
                    <div style={{ textAlign: 'start', marginBottom: 15 }}>
                      <Checkbox {...field} id="termsAndConditions" checked={getValues('terms')} clickablearea="checkbox">
                        <div className="caption1">
                          By signing up, you agree to our {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                          <TertiaryButton type="button" onClick={() => window.open('https://legal.keplerco.io/documents/terms-of-use/', '_blank')} style={{ height: 'fit-content' }}>
                            terms of service
                          </TertiaryButton>{' '}
                          and {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                          <TertiaryButton type="button" onClick={() => window.open('https://legal.keplerco.io/documents/privacy-policy/', '_blank')} style={{ height: 'fit-content' }}>
                            privacy policy
                          </TertiaryButton>
                        </div>
                      </Checkbox>
                    </div>

                    {!!fieldState.error && <div className="fieldErrorMessage">Please agree to our terms of service and privacy policy before continuing</div>}
                  </React.Fragment>
                )}
              />

              <Button type="button">Activate your account</Button>

              {passwordErrors !== undefined &&
                passwordErrors?.length > 0 &&
                passwordErrors.map((error: string, index: number) => {
                  return (
                    <React.Fragment key={'error_' + index}>
                      <div className="formErrorMessage">{error}</div>
                    </React.Fragment>
                  );
                })}
            </form>

            <div className={accountStyles.accountDivider}>Or activate using</div>

            {!!samlUrl && (
              <SSOButton
                ssoType="saml"
                onClick={async () => {
                  if (!!getValues('terms')) {
                    window.location.href = `${samlUrl}?returnUrl=${params.get('kt')},,,${params.get('code')}`;
                  } else {
                    setError('terms', { type: 'focus' }, { shouldFocus: true });
                  }
                }}
              />
            )}

            <SSOButton
              ssoType="google"
              onClick={() => {
                if (!!getValues('terms')) {
                  actions.externalLogin({ provider: 'Google', kt: params.get('kt'), code: params.get('code') });
                } else {
                  setError('terms', { type: 'focus' }, { shouldFocus: true });
                }
              }}
            />

            <SSOButton
              ssoType="microsoft"
              onClick={() => {
                if (!!getValues('terms')) {
                  actions.externalLogin({ provider: 'Microsoft', kt: params.get('kt'), code: params.get('code') });
                } else {
                  setError('terms', { type: 'focus' }, { shouldFocus: true });
                }
              }}
            />

            <SSOButton
              ssoType="linkedin"
              onClick={() => {
                if (!!getValues('terms')) {
                  actions.externalLogin({ provider: 'LinkedIn', kt: params.get('kt'), code: params.get('code') });
                } else {
                  setError('terms', { type: 'focus' }, { shouldFocus: true });
                }
              }}
            />
          </AccountPageLoaderLayer>
        </div>
      </div>
    </div>
  );
}
