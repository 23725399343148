import React, { useEffect, useReducer } from 'react';
import { ListItemLayout, ListLayout, Pager, Searchfield, SkillLevel, colourString, useMatchScreenWidth, scoreLevelRAGColour } from '@keplerco/core';
import * as store from './view-role-skills.store';
import { IViewRoleSkillsWidgetProps } from '../view-role.models';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { useAppActions, useAppState } from '../../../../../overmind';
import { FetchType, OrganizationLevelType, FetchStatus, SortField, EntityType } from '../../../../../enums';
import styles from './view-role-skills.module.css';
import classNames from 'classnames';
import { EmptyState } from '../../../../../components/general/empty-state/empty-state';
import { themedAssetUrl } from '../../../../../library/helpers/themed-asset-url';
import { CompanyEntitySearchParams } from '../../../../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../../../../library/helpers/permissions/extract-highest-organization-level';
import { EntitiesCardWidget } from '../../../../../components/cards/entities-card.widget';

export default function ViewRoleSkillsWidget({ path, role }: IViewRoleSkillsWidgetProps) {
  const actions = useAppActions();
  const { companyVariables, permissions, fetchState } = useAppState();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const isMobile = useMatchScreenWidth('mobile');

  useEffect(() => {
    const organizationLevel = extractHighestOrganizationLevel(permissions?.roleSkillManagement?.organizationLevels);
    const request: CompanyEntitySearchParams = {
      search: undefined,
      sortAscending: true,
      sortField: SortField.Name,
      pageSize: 4,
      page: 1,
      organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: undefined,
      searchGlobally: path === PagePath.roleSkillManagementGlobalRole,
      relatedType: EntityType.Role,
      roleSlug: role.slug,
    };
    dispatch({ type: store.ViewRoleSkillsActionTypes.SetRequest, payload: request });
  }, [role]);

  async function updateData() {
    if (!state.request) return actions.stopLoader(path);
    const response = path !== PagePath.yourCareerRole
      ? await actions.getCompanySkills(state.request)
      : await actions.getUserCompanySkills(state.request);
    dispatch({ type: store.ViewRoleSkillsActionTypes.SetData, payload: response });
    actions.stopLoader(path);
  }

  useEffect(() => {
    async function getData() {
      if (!state.request) return;
      actions.startLoader({ path, type: FetchType.PageFetching });
      const response = path !== PagePath.yourCareerRole
        ? await actions.getCompanySkills(state.request)
        : await actions.getUserCompanySkills(state.request);
      if (!!response?.entities.length) dispatch({ type: store.ViewRoleSkillsActionTypes.SetData, payload: response });
      actions.stopLoader(path);
    }

    !state.data ? getData() : updateData();
  }, [state.request]);

  return (
    <EntitiesCardWidget title="Skills" description="Skills assigned to the role">
      {!!state.data && (
        <Searchfield
          label="Search skills"
          loading={fetchState[path].status === FetchStatus.Active && fetchState[path].type === FetchType.Custom}
          onInput={search => {
            if (!state.request) return;
            actions.startLoader({ path, type: FetchType.Custom });
            dispatch({ type: store.ViewRoleSkillsActionTypes.SetRequest, payload: { ...state.request, search, page: 1 } });
          }}
          responsive
        />
      )}

      <div style={{ minHeight: 370, marginTop: 15, marginBottom: (!!state.data && state.data.totalPages > 1) ? 15 : 0 }}>
        {!!state.data?.entities.length ? (
          <ListLayout>
            {state.data.entities.map(entity => (
              <ListItemLayout key={entity.slug}>
                <div className={classNames('card', styles.skillCard)} style={{ gridTemplateColumns: (entity.level !== null && !isMobile) ? 'minmax(0px, 1fr) auto' : '1fr' }}>
                  <div style={{ overflow: 'hidden' }}>
                    <h5 className={classNames('heading5', styles.skillCardText)} style={{ color: colourString('lilac') }}>{entity.name}</h5>
                    <p className={classNames('caption2', styles.skillCardText)}>{entity.description}</p>
                  </div>

                  {entity.level !== null && (
                    <SkillLevel
                      level={entity.level ?? companyVariables.minLevel}
                      minLevel={companyVariables.minLevel}
                      maxLevel={companyVariables.maxLevel}
                      noLevel={false}
                      title="Expected proficiency"
                      dotColour={path !== PagePath.yourCareerRole ? scoreLevelRAGColour(entity.level ?? companyVariables.minLevel, companyVariables.minLevel, companyVariables.maxLevel) : 'baby-blue'}
                    />
                  )}
                </div>
              </ListItemLayout>
            ))}
          </ListLayout>
        ) : (
          <EmptyState
            badgeUrl={themedAssetUrl('graphics/empty-state-clouds.graphic.svg')}
            badgeIconWidth="full-width"
            subtitle="No skills found"
            centerText
          />
        )}
      </div>

      {!!state.data && state.data?.totalPages > 1 && (
        <Pager
          activePageNumber={state.request?.page ?? 1}
          pageCount={state.data?.totalPages}
          onPageChange={page => {
            if (!state.request || page === state.request.page) return;
            actions.startLoader({ path, type: FetchType.Custom });
            dispatch({ type: store.ViewRoleSkillsActionTypes.SetRequest, payload: { ...state.request, page } });
          }}
        />
      )}
    </EntitiesCardWidget>
  );
}

