import React, { useEffect, useState } from 'react';
import { IFrameModalProps } from './iframe-modal.models';
import { FullscreenModal } from '../../../general/iframe/fullscreen-modal/fullscreen-modal';
import { generateIFrameUrl } from '../iframe.helpers';
import { LoaderIcon } from '@keplerco/core';
import iFrameStyles from '../iframe.module.css';

export function IFrameModal(props: IFrameModalProps): JSX.Element {
  const { show, onClose, iFrameUrl } = props;

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);

  return (
    <FullscreenModal show={show} onClose={onClose}>
      <div style={{ width: '100%', height: '100%' }}>
        {loading ? (
          <div className={iFrameStyles.loader}>
            <div className={iFrameStyles.loaderContent}>
              <LoaderIcon />
              <h6 className="subtitle">Loading your learning content</h6>
            </div>
          </div>
        ) : (
          <iframe className={iFrameStyles.iFrame} src={generateIFrameUrl(iFrameUrl)} frameBorder={0}></iframe>
        )}
      </div>
    </FullscreenModal>
  );
}
