import React, { Fragment, useEffect, useState } from 'react';
import { Anchor, Button, Chip, colourString, DropdownSelect, KebabMenu, ListItemLayout, ListLayout, Panel } from '@keplerco/core';
import classNames from 'classnames';
import { TertiaryButton } from '../../../../../components/general/tertiary-button/tertiary-button';
import { SkillLevelsExplainerPanelLayout } from '../../../../../widgets/layouts/focus-panel-layouts/skill-levels-explainer-panel/skill-levels-explainer-panel.layout';
import { SelectSkillsLayout } from './select-skills.layout';
import { createLevelsArray } from '../../../../../library/helpers/create-levels-array';
import { useAppActions, useAppState } from '../../../../../overmind';
import { ISkillsStepLayoutProps } from './skills-step.models';
import { CompanySkillListItemResponse } from '../../../../../models/overmind/company-entity';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { StepSkeleton } from '../../../../../components/general/stepper/step/step.skeleton';
import StarsIcon from '../../../../../design/icons/stars.icon';
import { AstronautsIcon } from '../../../../../design/icons/astronauts.icon';

export function SkillsStepLayout({ path, parentIsLoading, role, roleSlug, skills, completeStep, goToPreviousStep }: ISkillsStepLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(false);
  const [skillLevelExplainerOpen, setSkillLevelExplainerOpen] = useState<boolean>(false);
  const [selectSkillsOpen, setSelectSkillsOpen] = useState<boolean>(false);
  const [selectedSkills, setSelectedSkills] = useState<CompanySkillListItemResponse[]>([]);
  const [loadingProficiencies, setLoadingProficiencies] = useState<boolean>(false);

  const [error, setError] = useState<boolean>();

  useEffect(() => {
    setSelectedSkills(structuredClone(skills));
  }, [skills]);

  useEffect(() => {
    if (!!selectedSkills.length) setError(undefined);
  }, [selectedSkills]);

  async function assignSkills(): Promise<CompanySkillListItemResponse[]> {
    if (!roleSlug) return [];

    const skillsToAssign = path === PagePath.roleSkillManagementWizardKeplerAI || path === PagePath.roleSkillManagementWizardImportRole ? selectedSkills : selectedSkills.filter(selectedSkill => !skills.some(skill => skill.slug === selectedSkill.slug));
    if (!skillsToAssign.length) return selectedSkills;

    const nextSelectedSkills: CompanySkillListItemResponse[] = structuredClone(selectedSkills);

    await Promise.all(
      skillsToAssign.map(async skill => {
        const skillSlug = await actions.addSkillToRoleRelation({
          roleSlug,
          skillSlug: skill.slug,
          skillName: skill.name,
          skillDescription: skill.description,
        });

        const nextSelectedSkill = nextSelectedSkills.find(currentSelectedSkill => currentSelectedSkill.name === skill.name);
        if (!!nextSelectedSkill) nextSelectedSkill.slug = skillSlug;
      })
    );

    setSelectedSkills(nextSelectedSkills);
    return nextSelectedSkills;
  }

  async function unassignSkills() {
    if (!roleSlug) return;
    if (path === PagePath.roleSkillManagementWizardImportRole) return;

    const skillsToUnassign = skills.filter(skill => !selectedSkills.some(selectedSkill => selectedSkill.slug === skill.slug));
    if (!skillsToUnassign.length) return;

    await Promise.all(
      skillsToUnassign.map(async skill => {
        await actions.removeSkillToRoleRelation({
          roleSlug,
          skillSlug: skill.slug!,
        });
      })
    );
  }

  async function assignExpectedLevels(selectedSkills: CompanySkillListItemResponse[]) {
    if (!roleSlug) return;

    const skillsToAssign = selectedSkills.filter(selectedSkill => {
      const skill = skills.find(skill => skill.slug === selectedSkill.slug);
      return !skill || skill.level !== selectedSkill.level;
    });

    if (!skillsToAssign.length) return;

    await Promise.all(
      skillsToAssign.map(async skill => {
        await actions.addExpectedLevelToRoleSkillRelation({
          roleSlug,
          skillSlug: skill.slug!,
          expectedLevel: skill.level,
        });
      })
    );
  }

  if (parentIsLoading || loading) return <StepSkeleton />;

  return (
    <Fragment>
      <div className="card" style={{ display: 'flex', flexDirection: 'column', gap: 24, width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <h5 className="heading5">Skills</h5>
          <p className="body" style={{ color: colourString('text_1') }}>
            Manage the skills required for this role.
          </p>
        </div>

        <div className="card action-card">
          {/* TODO */}
          <AstronautsIcon />

          <div className="action-card-content">
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <h3 className="subtitle">Assign skills to this role</h3>
              {!!selectedSkills.length && <Chip label={`${selectedSkills.length} skill(s)`} variant="tiny" borderColour="g" backgroundColour="g" colour="black" />}
            </div>

            <p className="body2">Choose skills for the role and define performance expectations.</p>
          </div>

          <Button type="button" arrow={false} filled onClick={() => setSelectSkillsOpen(true)}>
            Add skills
          </Button>
        </div>

        {!!selectedSkills.length && (
          <Fragment>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 15 }}>
              <div className="heading5">Results</div>

              <TertiaryButton type="button" style={{ marginLeft: 'auto' }} onClick={() => setSkillLevelExplainerOpen(true)}>
                View proficiency definitions
              </TertiaryButton>

              <Button
                type="button"
                isLoading={loadingProficiencies}
                loaderText="Suggesting..."
                square
                noShadow
                onClick={async () => {
                  setLoadingProficiencies(true);
                  const proficiencies = await actions.aiGenerateProficiencies({
                    role,
                    skills: selectedSkills,
                  });
                  setSelectedSkills(proficiencies ?? selectedSkills);
                  setLoadingProficiencies(false);
                }}
              >
                <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                  <StarsIcon tone='text' />
                  Suggest proficiency
                </div>
              </Button>
            </div>

            <div style={{ height: 400, overflowY: 'auto', paddingRight: 15 }}>
              <ListLayout>
                {selectedSkills.map((selectedSkill, index) => (
                  <ListItemLayout key={index}>
                    <div className={classNames('card')} style={{ display: 'grid', gridTemplateColumns: `auto 1fr 210px`, gap: 15, alignItems: 'center' }}>
                      <KebabMenu
                        items={[
                          {
                            label: 'Remove skill',
                            onClick: () =>
                              setSelectedSkills(currentState =>
                                currentState.filter(currentSkill => {
                                  if (!currentSkill.slug) return currentSkill.name !== selectedSkill.name;
                                  return currentSkill.slug !== selectedSkill.slug;
                                })
                              ),
                          },
                        ]}
                      />

                      <div>
                        <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                          {selectedSkill.name}
                        </div>
                        <div className="caption2">{selectedSkill.description ?? 'No description provided'}</div>
                      </div>

                      <DropdownSelect
                        label="Expected proficiency"
                        items={createLevelsArray(companyVariables.maxLevel, companyVariables.minLevel).map(level => ({
                          label: `Level ${level}`,
                          value: level.toString(),
                          onClick: () => {
                            setSelectedSkills(currentState => {
                              currentState.forEach(currentSkill => {
                                if (currentSkill.slug !== selectedSkill.slug) return;
                                selectedSkill.level = level;
                              });
                              return currentState;
                            });
                          },
                          selected: selectedSkill.level === level,
                        }))}
                        value={selectedSkill.level?.toString()}
                        responsive
                      />
                    </div>
                  </ListItemLayout>
                ))}
              </ListLayout>
            </div>
          </Fragment>
        )}

        {!!error && <div className={classNames('formErrorMessage')}>Please select at least one Skill</div>}
      </div>

      <footer className="card" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'var(--background)' }}>
        <div>
          {path !== PagePath.roleSkillManagementWizardKeplerAI && (
            <Anchor arrow reverse onClick={goToPreviousStep}>
              Back
            </Anchor>
          )}
        </div>

        <Anchor
          arrow
          onClick={async () => {
            if (!selectedSkills.length) return setError(true);

            setLoading(true);

            const tempSkills = await assignSkills();
            await unassignSkills();
            await assignExpectedLevels(tempSkills);

            completeStep();

            setLoading(false);
          }}
        >
          Next
        </Anchor>
      </footer>

      <Panel open={skillLevelExplainerOpen} onClose={() => setSkillLevelExplainerOpen(false)}>
        <SkillLevelsExplainerPanelLayout onClose={() => setSkillLevelExplainerOpen(false)} />
      </Panel>

      <Panel open={selectSkillsOpen} onClose={() => setSelectSkillsOpen(false)}>
        {selectSkillsOpen && (
          <SelectSkillsLayout
            skills={selectedSkills}
            onBack={() => setSelectSkillsOpen(false)}
            onAssign={selectedSkills => {
              setSelectedSkills(
                selectedSkills.map(selectedSkill => ({
                  slug: selectedSkill.slug,
                  name: selectedSkill.name,
                  description: selectedSkill.description,
                  level: selectedSkill.level,
                }))
              );
              setSelectSkillsOpen(false);
            }}
          />
        )}
      </Panel>
    </Fragment>
  );
}
