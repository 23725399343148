import React, { Fragment, useEffect, useState } from 'react';
import { FetchType } from '../../enums';
import { PersonalDetails } from '../../models/view/personal-details';
import { useAppActions, useAppState } from '../../overmind';
import { PageLoaderLayer } from '../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { PagePath } from '../../navigation/navigation.enums';
import { ErrorFieldState, IFormValues, Country, defaultFormValues, initialErrorFieldState } from './onboarding.models';
import { Anchor, Button, DropdownItem, DropdownSearch, IPillButton, PillButtonGroup, TextField, colourString, useMatchScreenWidth } from '@keplerco/core';
import classNames from 'classnames';
import { PeriodOfExperience, PeriodOfExperienceDescription } from '../../enums/period-of-experience';
import { FlagIcon } from '../../design/icons/flag.icon';
import layoutStyles from '../../design/layout.module.css';
import styles from './onboarding.module.css';

export function OnboardingPage(): JSX.Element {
  const actions = useAppActions();
  const { user } = useAppState();

  const isMobile = useMatchScreenWidth('mobile');

  const [view, setView] = useState<'personal' | 'professional'>('personal');
  const [formValues, setFormValues] = useState<IFormValues>(defaultFormValues);
  const [errorField, setErrorField] = useState<ErrorFieldState>(initialErrorFieldState);

  const [countryItems, setCountryItems] = useState<DropdownItem[]>([]);

  const [pillButtons] = useState<IPillButton[]>([
    { id: PeriodOfExperience.ZeroToTwo, label: PeriodOfExperienceDescription.ZeroToTwo },
    { id: PeriodOfExperience.TwoToFour, label: PeriodOfExperienceDescription.TwoToFour },
    { id: PeriodOfExperience.FourToSeven, label: PeriodOfExperienceDescription.FourToSeven },
    { id: PeriodOfExperience.SevenPlus, label: PeriodOfExperienceDescription.SevenPlus },
  ]);

  useEffect(() => {
    async function getData() {
      if (!user) return actions.startLoader({ path: PagePath.onboarding, type: FetchType.PageFetching });

      setView('personal');

      const countries: Country[] | undefined = await actions.getCountries();
      let filteredCountries: DropdownItem[] = [];
      if (!!countries) {
        filteredCountries = countries
          .filter(country => country.iso !== null)
          .map(country => ({
            label: country.name,
            value: country.id!.toString(),
            icon: <FlagIcon isoCode={country.iso} />,
          }));
      }
      setCountryItems(filteredCountries);

      const profile = await actions.getProfile();
      setFormValues(() => {
        const firstName = (user.firstName ?? '').trim() === '' ? '' : user.firstName;

        return {
          ...defaultFormValues,
          firstName: firstName,
          lastName: (user.lastName ?? '').trim() === '' ? '' : user.lastName,
          preferredName: (user.preferredName ?? '').trim() === '' ? firstName : user.preferredName,
          jobTitle: (user.jobTitle ?? '').trim() === '' ? '' : user.jobTitle,
          countryId: profile?.countryId?.toString(),
        } as IFormValues;
      });

      actions.stopLoader(PagePath.onboarding);
    }

    getData();
  }, [user]);

  async function submitForm() {
    setErrorField(initialErrorFieldState);

    actions.startLoader({ path: PagePath.onboarding, type: FetchType.Sending });

    const personalDetails = {
      ...formValues,
      countryId: !!formValues.countryId ? parseInt(formValues.countryId) : undefined,
    } as PersonalDetails;

    await actions.submitPersonalDetails(personalDetails);

    await actions.getUser();

    actions.stopLoader(PagePath.onboarding);
  }

  function onClickNextHandler() {
    switch (view) {
      case 'personal': {
        if (!!formValues.firstName && !!formValues.lastName && !!formValues.preferredName && formValues.countryId !== undefined) {
          setErrorField(initialErrorFieldState);
          user?.displayJobInformationForm ? setView('professional') : submitForm();
          break;
        }

        setErrorField(currentState => {
          const nextState = structuredClone(currentState);
          nextState.firstName = !formValues.firstName;
          nextState.lastName = !formValues.lastName;
          nextState.preferredName = !formValues.preferredName;
          nextState.countryId = !formValues.countryId;
          return nextState;
        });
        break;
      }

      case 'professional': {
        if (!!formValues.jobTitle && formValues.periodOfExperience !== undefined) {
          setErrorField(initialErrorFieldState);
          submitForm();
          break;
        }

        setErrorField(currentState => {
          const nextState = structuredClone(currentState);
          nextState.jobTitle = !formValues.jobTitle;
          nextState.periodOfExperience = !formValues.periodOfExperience;
          return nextState;
        });

        break;
      }

      default: {
        break;
      }
    }
  }

  return (
    <PageLoaderLayer path={PagePath.onboarding}>
      {view === 'personal' && (
        <Fragment>
          <header style={{ textAlign: 'center' }}>
            <h1 className="heading1">{user?.displayJobInformationForm ? `Let's c` : `C`}reate your profile</h1>
            <h6 className="subtitle">{user?.displayJobInformationForm ? `First we'll` : `Let's`} confirm your details, then we'll dive right into your skills analysis.</h6>
          </header>

          <form id="onboardingPersonalDetails" className={styles.form} autoComplete="off">
            <TextField
              type="text"
              label="What is your first name?"
              large
              responsive
              validation={{ dirty: errorField.firstName, invalid: errorField.firstName, message: 'Please enter your first name' }}
              defaultValue={formValues.firstName}
              onChange={event => {
                const value = event.currentTarget.value;
                setFormValues(currentState => ({ ...currentState, firstName: value }));
                setErrorField(currentState => ({ ...currentState, firstName: !value }));
              }}
            />

            <TextField
              type="text"
              label="What is your last name?"
              large
              responsive
              validation={{ dirty: errorField.lastName, invalid: errorField.lastName, message: 'Please enter your last name' }}
              defaultValue={formValues.lastName}
              onChange={event => {
                const value = event.currentTarget.value;
                setFormValues(currentState => ({ ...currentState, lastName: value }));
                setErrorField(currentState => ({ ...currentState, lastName: !value }));
              }}
            />

            <TextField
              type="text"
              label="What would you like us to call you?"
              large
              responsive
              validation={{ dirty: errorField.preferredName, invalid: errorField.preferredName, message: 'Please enter your nickname' }}
              defaultValue={formValues.preferredName}
              onChange={event => {
                const value = event.currentTarget.value;
                setFormValues(currentState => ({ ...currentState, preferredName: value }));
                setErrorField(currentState => ({ ...currentState, preferredName: !value }));
              }}
            />

            <DropdownSearch
              label="Where are you based?"
              items={countryItems}
              large
              responsive
              validation={{ invalid: errorField.countryId, dirty: errorField.countryId, message: 'Please select your country' }}
              value={formValues.countryId}
              onChange={event => {
                const value = event.target.value;
                if (!value) return; // TODO: look at dropdown search triggering onChange incorrectly when it initializes
                setFormValues(currentState => ({ ...currentState, countryId: value }));
                setErrorField(currentState => ({ ...currentState, countryId: !value }));
              }}
            />
          </form>
        </Fragment>
      )}

      {view === 'professional' && (
        <Fragment>
          <header style={{ textAlign: 'center', marginBottom: 30 }}>
            <h1 className="heading1">Hello, {formValues.preferredName ?? user?.firstName}</h1>
          </header>

          <form id="onboardingProfessionalDetails" className={styles.form}>
            <TextField
              type="text"
              label="What is your job title?"
              large
              responsive
              validation={{ dirty: errorField.jobTitle, invalid: errorField.jobTitle, message: 'Please enter your job title' }}
              defaultValue={formValues.jobTitle}
              onChange={event => {
                const value = event.currentTarget.value;
                setFormValues(currentState => ({ ...currentState, jobTitle: value }));
                setErrorField(currentState => ({ ...currentState, jobTitle: !value }));
              }}
            />

            <div style={{ width: '100%' }}>
              <div className="caption2" style={{ color: colourString('accent-3'), marginBottom: 10 }}>
                How long have you been employed in your current role?
              </div>

              <PillButtonGroup
                buttons={pillButtons}
                layout={isMobile ? 'fill' : 'fit'}
                backgroundColour="borders_2"
                activeTextColour='text'
                onClick={buttons => {
                  setFormValues(currentState => ({ ...currentState, periodOfExperience: buttons[0].id }));
                  setErrorField(currentState => ({ ...currentState, periodOfExperience: false }));
                }}
              />

              <div className={classNames('fieldValidation fieldErrorMessage', { hidden: !errorField.periodOfExperience })}>Please enter your experience</div>
            </div>
          </form>
        </Fragment>
      )}

      <footer className={layoutStyles.footer}>
        <div className={layoutStyles.footerContent}>
          {view === 'professional' ? (
            <Anchor arrow reverse block onClick={() => setView('personal')}>
              Back
            </Anchor>
          ) : (
            <div />
          )}

          <Button type="button" onClick={onClickNextHandler}>
            Next
          </Button>
        </div>
      </footer>
    </PageLoaderLayer>
  );
}
